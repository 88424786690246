import { Component, OnInit, inject } from '@angular/core';
import { TodosFirebaseService } from '../../services/todosFirebase.service';
import { MatButtonModule } from '@angular/material/button';
import { pipe, take } from 'rxjs';

@Component({
  selector: 'app-rankings',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './rankings.component.html',
  styleUrl: './rankings.component.scss',
})
export class RankingsComponent implements OnInit {
  todosFirebaseService = inject(TodosFirebaseService);

  ngOnInit(): void {
    this.todosFirebaseService
      .getTodos()
      .pipe(take(1))
      .subscribe((result) => {
        console.log(result);
      });
  }

  createTodo(text: string) {
    this.todosFirebaseService.addTodo(text).subscribe(
      (id) => {
        console.log('Created TODO. ID: ' + id);
      },
      (error) => {
        console.log('real error', error);
      }
    );
  }
}
