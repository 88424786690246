import * as THREE from 'three';
import { ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { CharacterControls } from '../../controls/characterControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

@Injectable({ providedIn: 'root' })
export class EngineService implements OnDestroy {
  private canvas: HTMLCanvasElement | null = null;
  private renderer: THREE.WebGLRenderer | null = null;
  private camera: THREE.PerspectiveCamera | null = null;
  private scene: THREE.Scene = new THREE.Scene();
  private gltfLoader = new GLTFLoader();
  // private light: THREE.AmbientLight | null = null;
  private cube: THREE.Mesh | null = null;
  private frameId: number | null = null;
  characterControls?: CharacterControls;
  orbitControls?: OrbitControls;
  clock = new THREE.Clock();
  delta = this.clock.getDelta();
  stats = new Stats();
  keysPressed = {};

  public constructor(private ngZone: NgZone) {
    this.light();
    this.scene.background = new THREE.Color(0xa8def0);
  }

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
    if (this.renderer != null) {
      this.renderer.dispose();
      this.renderer = null;
      this.canvas = null;
    }
  }

  public createScene(canvas: ElementRef<HTMLCanvasElement>): void {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas.nativeElement;

    // RENDERER
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true, // transparent background
      antialias: true, // smooth edges
    });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.shadowMap.type = THREE.PCFShadowMap;
    this.renderer.shadowMap.enabled = true;

    // create the scene
    // this.scene = new THREE.Scene();
    this.scene.add(new THREE.GridHelper());

    // CAMERA
    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    this.camera.position.y = 5;
    this.camera.position.z = 5;
    this.camera.position.x = 0;

    // soft white light
    // this.light = new THREE.AmbientLight(0x404040);
    // this.light.position.z = 10;
    const directionalLight = new THREE.DirectionalLight(0xffffff, Math.PI);
    directionalLight.position.set(1, 1, 1);
    directionalLight.castShadow = true;
    // directionalLight.shadow.camera.near = 0;
    // directionalLight.shadow.camera.far = 10;
    this.scene.add(directionalLight);
    this.scene.add(new THREE.DirectionalLightHelper(directionalLight));

    // CONTROLS
    this.orbitControls = new OrbitControls(
      this.camera,
      this.renderer.domElement
    );
    this.orbitControls.enableDamping = true;
    this.orbitControls.minDistance = 5;
    this.orbitControls.maxDistance = 15;
    this.orbitControls.enablePan = true;
    this.orbitControls.maxPolarAngle = Math.PI / 2 - 0.05;
    this.orbitControls.update();
    document.body.appendChild(this.stats.dom);

    // FLOOR
    this.generateFloor();

    // MODEL WITH ANIMATIONS
    // var characterControls: CharacterControls;
    // new GLTFLoader().load('models/Soldier.glb', function (gltf) {
    //   const model = gltf.scene;
    //   model.traverse(function (object: any) {
    //     if (object.isMesh) object.castShadow = true;
    //   });
    //   scene.add(model);

    //   const gltfAnimations: THREE.AnimationClip[] = gltf.animations;
    //   const mixer = new THREE.AnimationMixer(model);
    //   const animationsMap: Map<string, THREE.AnimationAction> = new Map();
    //   gltfAnimations
    //     .filter((a) => a.name != 'TPose')
    //     .forEach((a: THREE.AnimationClip) => {
    //       animationsMap.set(a.name, mixer.clipAction(a));
    //     });

    //   characterControls = new CharacterControls(
    //     model,
    //     mixer,
    //     animationsMap,
    //     orbitControls,
    //     camera,
    //     'Idle'
    //   );
    // });

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshToonMaterial({ color: 0x00ff00 });
    this.cube = new THREE.Mesh(geometry, material);
    this.cube.castShadow = true;
    this.cube.position.y = 0.5;

    this.scene.add(this.cube);

    //Plane Example
    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(10, 10),
      new THREE.MeshToonMaterial({ color: 0x888888 })
    );
    plane.rotation.x = -Math.PI / 2;
    plane.receiveShadow = true;
    this.scene.add(plane);

    this.loadCharacter();
    this.turnOnControls();
  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render();
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.render();
        });
      }

      window.addEventListener('resize', () => {
        this.resize();
      });
    });
  }

  public render(): void {
    this.renderer?.setAnimationLoop(this.handleFrame.bind(this));
  }

  public handleFrame(): void {
    this.stats.update();
    if (this.scene && this.renderer && this.camera) {
      let mixerUpdateDelta = this.clock.getDelta();
      if (this.characterControls) {
        this.characterControls.update(mixerUpdateDelta, this.keysPressed);
      }
      this.orbitControls?.update();
      this.renderer.render(this.scene, this.camera);
    }
  }

  public resize(): void {
    if (!this.camera) {
      // TODO: Error Handling?
      return;
    }
    if (!this.renderer) {
      // TODO: Error Handling?
      return;
    }
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  public light() {
    this.scene.add(new THREE.AmbientLight(0xffffff, 0.7));

    const dirLight = new THREE.DirectionalLight(0xffffff, 1);
    dirLight.position.set(-60, 100, -10);
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 50;
    dirLight.shadow.camera.bottom = -50;
    dirLight.shadow.camera.left = -50;
    dirLight.shadow.camera.right = 50;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 200;
    dirLight.shadow.mapSize.width = 4096;
    dirLight.shadow.mapSize.height = 4096;
    this.scene.add(dirLight);
    // scene.add( new THREE.CameraHelper(dirLight.shadow.camera))
  }

  generateFloor() {
    // TEXTURES
    const textureLoader = new THREE.TextureLoader();
    const placeholder = textureLoader.load(
      '../../graphics/textures/placeholder/placeholder.png'
    );
    const sandBaseColor = textureLoader.load(
      '../../graphics/textures/sand/Sand 002_COLOR.jpg'
    );
    const sandNormalMap = textureLoader.load(
      '../../graphics/textures/sand/Sand 002_NRM.jpg'
    );
    const sandHeightMap = textureLoader.load(
      '../../graphics/textures/sand/Sand 002_DISP.jpg'
    );
    const sandAmbientOcclusion = textureLoader.load(
      '../../graphics/textures/sand/Sand 002_OCC.jpg'
    );

    const WIDTH = 80;
    const LENGTH = 80;

    const geometry = new THREE.PlaneGeometry(WIDTH, LENGTH, 512, 512);
    const material = new THREE.MeshStandardMaterial({
      map: sandBaseColor,
      normalMap: sandNormalMap,
      displacementMap: sandHeightMap,
      displacementScale: 0.1,
      aoMap: sandAmbientOcclusion,
    });
    this.wrapAndRepeatTexture(material.map!);
    this.wrapAndRepeatTexture(material.normalMap!);
    this.wrapAndRepeatTexture(material.displacementMap!);
    this.wrapAndRepeatTexture(material.aoMap!);
    // const material = new THREE.MeshPhongMaterial({ map: placeholder})

    const floor = new THREE.Mesh(geometry, material);
    floor.receiveShadow = true;
    floor.rotation.x = -Math.PI / 2;
    this.scene.add(floor);
  }

  wrapAndRepeatTexture(map: THREE.Texture) {
    map.wrapS = map.wrapT = THREE.RepeatWrapping;
    map.repeat.x = map.repeat.y = 10;
  }

  public loadCharacter() {
    this.gltfLoader.load('../../graphics/models/Soldier.glb', (gltf) => {
      const model = gltf.scene;
      model.traverse(function (object: any) {
        if (object.isMesh) object.castShadow = true;
      });
      this.scene.add(model);

      const gltfAnimations: THREE.AnimationClip[] = gltf.animations;
      const mixer = new THREE.AnimationMixer(model);
      const animationsMap: Map<string, THREE.AnimationAction> = new Map();
      gltfAnimations
        .filter((a) => a.name != 'TPose')
        .forEach((a: THREE.AnimationClip) => {
          animationsMap.set(a.name, mixer.clipAction(a));
        });

      this.characterControls = new CharacterControls(
        model,
        mixer,
        animationsMap,
        this.orbitControls!,
        this.camera!,
        'Idle'
      );
    });
  }

  turnOnControls() {
    // CONTROL KEYS
    // const keyDisplayQueue = new KeyDisplay();
    document.addEventListener(
      'keydown',
      (event) => {
        // keyDisplayQueue.down(event.key);
        if (event.shiftKey && this.characterControls) {
          this.characterControls.switchRunToggle();
        } else {
          (this.keysPressed as any)[event.key.toLowerCase()] = true;
        }
      },
      false
    );
    document.addEventListener(
      'keyup',
      (event) => {
        // thiskeyDisplayQueue.up(event.key);
        (this.keysPressed as any)[event.key.toLowerCase()] = false;
      },
      false
    );
  }
}
