import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { SocketService } from '../../services/socket.service';
import { Subscription } from 'rxjs';
import * as THREE from 'three';
import { GameComponent } from '../game/game.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MatButtonModule, CommonModule, GameComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, OnDestroy {
  authService = inject(AuthService);
  socketService = inject(SocketService);
  userSubscription: Subscription;
  userToken: string | undefined | null = null;

  @ViewChild('canvas')
  private canvasRef?: ElementRef;

  constructor() {
    this.userSubscription = this.authService.user$.subscribe((user) => {
      if (user) {
        user
          .getIdToken()
          .then((token) => {
            this.userToken = token;
          })
          .catch((error) => {
            // TODO: some sort of error msg for user
            console.log('Error retrieving error token');
          });
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  connectToServer() {
    if (!this.userToken) {
      // TODO: Some sort of error msg for user
    } else {
      this.socketService.setupSocketConnection(this.userToken);
    }
  }
}
