import { Routes } from '@angular/router';
import { RankingsComponent } from './components/rankings/rankings.component';
import { HomeComponent } from './components/home/home.component';
import { StoreComponent } from './components/store/store.component';
import { AuthGuard } from '@angular/fire/auth-guard';

export const routes: Routes = [
  { component: RankingsComponent, path: 'rankings' },
  { component: HomeComponent, path: '' },
  { component: StoreComponent, path: 'store', canActivate: [AuthGuard] },
];
