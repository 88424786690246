import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './services/auth.service';
import { TopbarComponent } from './components/topbar/topbar.component';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TopbarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'Client';
  authService = inject(AuthService);

  ngOnInit(): void {
    console.log('is env prod', environment.production);
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.authService.currentUserSig.set(user);
      } else {
        this.authService.currentUserSig.set(null);
      }
      console.log('currentUser:', this.authService.currentUserSig());
    });
  }

  loginWithGoogle() {
    this.authService.loginWithGoogle();
  }
}
