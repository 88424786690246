import { Injectable, inject, signal } from '@angular/core';
import {
  Auth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  user,
  User,
  signOut,
} from '@angular/fire/auth';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  firebaseAuth = inject(Auth);
  user$ = user(this.firebaseAuth);

  // Undefined for before the app knows whether user is logged in or not
  currentUserSig = signal<User | null | undefined>(undefined);

  async loginWithGoogle() {
    // this.firebaseAuth.
    try {
      const provider = new GoogleAuthProvider();
      const credential = await signInWithPopup(this.firebaseAuth, provider);
      console.log('user credential', credential);
    } catch (error) {}
  }

  logout(): Observable<void> {
    const promise = signOut(this.firebaseAuth);
    return from(promise);
  }
}
