export const environment = {
  production: false,
  apiUrl: 'http://localhost:4001/api',
  socketUrl: 'https://kuabs.onrender.com',
  firebase: {
    apiKey: 'AIzaSyDr8FDu6d1elrmv_GQVrxx5efVzH-QZlW8',
    authDomain: 'kuabsdevelopment.firebaseapp.com',
    projectId: 'kuabsdevelopment',
    storageBucket: 'kuabsdevelopment.appspot.com',
    messagingSenderId: '1015951769186',
    appId: '1:1015951769186:web:a506baa80ffb74120c5b3b',
    measurementId: 'G-RTESGY6Y05',
  },
};
