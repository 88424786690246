<mat-toolbar>
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <span>Kuabs</span>
  <span class="example-spacer"></span>
  <a *ngIf="authService.user$ | async" mat-stroked-button routerLink="/store"
    >Store</a
  >
  <button
    *ngIf="(authService.user$ | async) === null"
    (click)="loginWithGoogle()"
    mat-stroked-button
    color="primary"
  >
    Login
  </button>
  <button
    *ngIf="authService.user$ | async"
    (click)="logout()"
    mat-stroked-button
    color="primary"
  >
    Logout
  </button>
  <button
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>favorite</mat-icon>
  </button>
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with share icon"
  >
    <mat-icon>share</mat-icon>
  </button>
</mat-toolbar>
